@mixin list-entities-theme($theme) {

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $is-dark: map-get($theme, is-dark);

    // Overview
    app-list-entities {

        .center {

            .header {

                .search-wrapper {
                    background: map-get($background, card);

                    .sidebar-toggle {
                        border-right-color: map-get($foreground, divider);
                    }

                    .search {

                        input {
                            background: map-get($background, card);
                            color: map-get($foreground, text);
                        }
                    }
                }
            }
        }
    }
    // List
    list {
        border-right-color: map-get($foreground, divider);
    }
    //List item
    list-item {
        border-bottom-color: map-get($foreground, divider);

        &.unread {

            .info {

                .row-2 {
                    color: map-get($foreground, text);
                }
            }
        }

        &.current-item {
            background: map-get($background, hover);
        }

        &.selected {
            @if ($is-dark) {
                background: map-get($background, background);
            }
            @else {
                background: map-get($accent, 50);
            }

            .info {

                .row-2 {

                    .labels {
                        @if ($is-dark) {
                            background: map-get($background, background);
                        }
                        @else {
                            background: map-get($accent, 50);
                        }
                    }
                }
            }
        }

        &.archived {
            background: map-get($warn, 50);
        }

        .info {

            .row-2 {
                color: map-get($foreground, secondary-text);

                .labels {

                    .label {
                        background-color: rgba(0, 0, 0, 0.08);
                    }
                }
            }
        }
    }
    // Details
    details {

        .header {
            border-bottom-color: map-get($foreground, divider);

            .label {
                background-color: rgba(0, 0, 0, 0.08);
            }
        }

        .content {

            .to {
                color: map-get($foreground, secondary-text);
            }

            .info {

                .avatar {
                    background: map-get($accent, default);
                }

                .details {

                    .detail {
                        color: map-get($foreground, secondary-text);
                    }
                }
            }
        }

        .attachments {
            border-top-color: map-get($foreground, divider);
        }
    }
}
