@mixin generic-list-entities-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $is-dark: map-get($theme, is-dark);

  // Overview
  generic-list-entities {
    .center {
      .header {
        
        .search-wrapper {
          background: #f5f5f5;
          flex-wrap: wrap;
          width: 100%;
          flex-wrap: wrap;
          align-items: center;
          @include mat.elevation(7);

          .sidebar-toggle {
            margin: 0;
            width: 46px;
            height: 46px;
            border-radius: 0;
            border-right: 1px solid;
            border-right-color: map-get($foreground, divider);
          }

          .sidebar-toggle-button {
            margin: 0;
            height: 46px;
            border-radius: 0;
            border-right: 1px solid;
          }

          .settings-sidebar-toggle {
            margin: 0;
            width: 46px;
            height: 46px;
            border-radius: 0;
            border-left: 1px solid;
          }

          .settings-sidebar-toggle-button {
            min-width: 46px;
            margin: 0;
            height: 46px;
            border-radius: 0;
            border-left: 1px solid;
          }

          .search {
            flex:1 1 0%;
            height: 46px;
            line-height: 36px;
            padding: 6px 10px;

            input {
              height: 36px;
              padding-left: 16px;
              border: none;
              outline: none;
              background: map-get($background, card);
              color: map-get($foreground, text);
            }
          }
        }
      }
    }
  }

  // List
  generic-list {
    border-right-color: map-get($foreground, divider);
  }

  //List item
  generic-list-item {
    border-bottom-color: map-get($foreground, divider);

    &.unread {
      .info {
        .row-2 {
          color: map-get($foreground, text);
        }
      }
    }

    &.current-item {
      background: map-get($background, hover);
    }

    &.selected {
      @if ($is-dark) {
        background: map-get($background, background);
      }

      @else {
        background: map-get($accent, 50);
      }

      .info {
        .row-2 {
          .labels {
            @if ($is-dark) {
              background: map-get($background, background);
            }

            @else {
              background: map-get($accent, 50);
            }
          }
        }
      }
    }

    &.archived {
      background: map-get($warn, 50);
    }

    .info {
      .row-2 {
        color: map-get($foreground, secondary-text);

        .labels {
          .label {
            background-color: rgba(0, 0, 0, 0.08);
          }
        }
      }
    }
  }

  // Details
  details {
    .header {
      border-bottom-color: map-get($foreground, divider);

      .label {
        background-color: rgba(0, 0, 0, 0.08);
      }
    }

    .content {
      .to {
        color: map-get($foreground, secondary-text);
      }

      .info {
        .avatar {
          background: map-get($accent, default);
        }

        .details {
          .detail {
            color: map-get($foreground, secondary-text);
          }
        }
      }
    }

    .attachments {
      border-top-color: map-get($foreground, divider);
    }
  }
}


generic-subset-entities {
  padding: 20px;

  p-table {
    height: 100% !important;
  }
}