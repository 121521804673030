// Import Fuse core library
@import "app/core/@fuse/scss/core";
@import "material-icons/iconfont/material-icons.scss";
@import "~primeicons/primeicons.css";
// Import app.theme.scss
@import "app/layout/layout.theme.scss";

@font-face {
  font-family: "Copperplate Gothic Bold Regular";
  src: local("Copperplate Gothic Bold Regular"),
    url(./assets/fonts/CopperplateGothicBoldRegular.ttf) format("truetype");
}

.ps__rail-y:hover {
  background-color: transparent !important;
}

.ps__rail-x:hover {
  background-color: transparent !important;
}

.own {
  border-left: 5px solid #e6e6e6;
}

.left-padding-desktop {
  @media (min-width: 1024px) {
    padding-left: 10px;
  }
}
.page-layout.carded.left-sidebar > .center,
.page-layout.carded.right-sidebar > .center {
  padding: 0px;
}
// snackbar
.mat-simple-snackbar {
  color: #e13759 !important;
  align-items: flex-end !important;

  span {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 400px;
    position: relative;
  }
}
.cdk-global-overlay-wrapper {
  align-items: start !important;
  margin-top: 60px;
}
.mat-snack-bar-container {
  max-width: 100% !important;
  background: #282c36 !important;

  simple-snack-bar {
    span {
      word-break: break-all;
    }
  }
}

/*.mat-progress-bar {
    display: inline-table !important;
}*/
.logo-icon {
  margin-right: 15px;
}
.logo-text {
  font-size: 16px;
}
.p-dialog .p-dialog-header {
  background: whitesmoke !important;
  cursor: move;
  text-align: center;
}
.mat-select-panel mat-option.mat-option {
  height: unset;
}

.mat-option-text.mat-option-text {
  white-space: normal;
}
.mat-select-value-text {
  white-space: normal !important;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mat-select-trigger {
  height: unset;
}
body .p-component {
  font-size: 16px;
}
body .p-component th {
  font-size: 14px;
  font-weight: 400 !important;
}

.tooltip-container {
  text-align: center;
  z-index: 1000000;
  position: fixed;
  padding: 6px 6px;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  line-height: initial;
  color: white;
  width: auto;
  background: #111111ee;
  box-sizing: border-box;
  opacity: 0;
  border-radius: 5px;
  transform: translate(-50%, -30%);
  -webkit-animation: tooltip-slide 0.18s ease-out 0.5s;
  animation: tooltip-slide 0.18s ease-out 0.5s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  pointer-events: none;
}

@keyframes tooltip-slide {
  0% {
    opacity: 0;
    transform: translate(-50%, -30%);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

.ql-container {
  font-size: 17px !important;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.ql-bubble .ql-tooltip {
  max-width: 108px;
  z-index: 90;
}

.ql-editor i {
  font-size: inherit;
}

.ql-toolbar {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

.ql-editor {
  overflow-y: visible !important;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.ql-editor[contenteditable="true"] {
  box-shadow: 0px 0px 0px 1px;
  transition: box-shadow 0.2s;
  /* border-radius */
  -webkit-border-top-left-radius: 0px;
  -webkit-border-top-right-radius: 0px;
  -moz-border-radius-topleft: 0px;
  -moz-border-radius-topright: 0px;
  border-radius: 5px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  /* box-shadow */
  -webkit-box-shadow: rgba(0, 0, 0, 0.8) 0px 0 1px;
  -moz-box-shadow: rgba(0, 0, 0, 0.8) 0 0 1px;
  box-shadow: rgba(0, 0, 0, 0.8) 0 0 1px;
}

.ql-editor[contenteditable="true"]:focus,
.ql-editor[contenteditable="true"]:hover {
  box-shadow: black 0px 0px 0px 2px;
}

quill-editor.ng-invalid .ql-container .ql-editor {
  -webkit-box-shadow: rgba(224, 64, 54, 1) 0px 0 13px;
  -moz-box-shadow: rgba(224, 64, 54, 1) 0 0 13px;
  box-shadow: rgba(224, 64, 54, 1) 0 0 13px;
}

.ql-container.ql-bubble:not(.ql-disabled) a {
  white-space: initial !important;
}
